import React, { useContext } from "react";
import { TranslationContext } from "@src/components/translation/TranslationContext";
import Modal from "react-modal";
import { ClassNames, css, SerializedStyles } from "@emotion/react";
import styled, { CSSObject } from "@emotion/styled";
import { mediaQueries } from "@src/styles/theme";
import { CloseIcon } from "@src/components/nessie-web";

const defaultModalDialogStyle = css`
  position: relative;
  box-sizing: border-box;
  margin: 10vh auto;
  padding: 30px 18px;

  overflow: auto;
  width: 85%;
  background: #fff;
  z-index: 1000;
  border-radius: 30px;
  background-clip: padding-box;
  outline: 0;
  ${mediaQueries[0]} {
    max-width: 552px;

    padding: 30px 56px 56px 56px;
  }
`;

const defaultOverlayStyle = css`
  background-color: #16134a99;
  position: fixed;
  top: 0px;
  left: 0px;
  right: 0px;
  bottom: 0px;
  overflow-y: auto;
  z-index: 99999;

  backdrop-filter: blur(6px);
`;

const fullScreenStyle = css`
  width: 100vw !important;
  height: 100% !important;
  max-width: 100vw !important;
  margin: 0 !important;
  overflow-y: auto !important;
`;

const fullPageStyle = css`
  ${fullScreenStyle};
  border-radius: 0;
`;

const CloseButton = styled.button`
  position: absolute;
  top: 24px;
  right: 24px;
  width: 36px;
  height: 36px;
  cursor: pointer;
  background-color: #1a192d;
  border-radius: 18px;
  border: 0;
  display: flex;
  justify-content: center;
  align-items: center;
`;

type DojoIslandsModalProps = {
  closeModal?: () => void;
  children?: any;
  labelledby?: string;
  fullPage?: boolean;
  modalDialogStyle?: CSSObject | SerializedStyles;
  closeModalAction?: () => void;
  forceShowClose?: boolean;
};

export const DojoIslandsModal = ({
  closeModal,
  closeModalAction,
  labelledby,
  modalDialogStyle,
  children,
  fullPage, // The modal will look like a page with no border radius.
}: DojoIslandsModalProps) => {
  const t = useContext(TranslationContext);

  return (
    <ClassNames>
      {({ css: classCSS }) => {
        const modalClass = () => {
          if (fullPage) {
            return classCSS`${defaultModalDialogStyle}; ${modalDialogStyle}; ${fullPageStyle}`;
          } else {
            return classCSS`${defaultModalDialogStyle}; ${modalDialogStyle}`;
          }
        };
        return (
          <Modal
            isOpen={true}
            aria={labelledby ? { labelledby: "modal-heading" } : undefined}
            className={modalClass()}
            overlayClassName={classCSS`${defaultOverlayStyle}; ${
              fullPage ? "overflow: hidden" : "transition: opacity 0.15s linear;"
            }`}
            onRequestClose={closeModal}
            onAfterClose={closeModalAction}
            style={{ content: fullPage ? { transform: "none" } : undefined }}
          >
            <CloseButton aria-label={t.translate("layouts.main.close_dialog") as string} onClick={closeModal}>
              <CloseIcon color="dt_white" />
            </CloseButton>

            {children}
          </Modal>
        );
      }}
    </ClassNames>
  );
};
