// Copied from https://github.com/classdojo/monsterverse/blob/main/packages/modules/api-types/src/index.ts
import type { paths } from "@classdojo/ts-api-types";
export type { components, paths } from "@classdojo/ts-api-types";

interface RouteWithJsonRequestBody {
  requestBody: {
    content: {
      "application/json": unknown;
    };
  };
}
interface RouteWithJsonResponse {
  responses: {
    200: {
      content: {
        "application/json": unknown;
      };
    };
  };
}

interface RouteWithEmptyResponse {
  responses: {
    200: unknown;
  };
}

interface RouteWithRequestParameters {
  parameters: {
    path?: {
      [key: string]: unknown;
    };
    query?: {
      [key: string]: unknown;
    };
  };
}
interface RouteWithRequestPathParameters {
  parameters: {
    path: {
      [key: string]: string | number;
    };
  };
}
interface RouteWithRequestQueryParameters {
  parameters: {
    query: {
      [key: string]: string | boolean;
    };
  };
}

export type APIRequestParameters<
  Path extends keyof paths,
  Method extends keyof paths[Path],
> = Method extends keyof paths[Path] ? RequestParametersFromRouteDefinition<paths[Path][Method]> : never;

export type APIResponse<Path extends keyof paths, Method extends keyof paths[Path]> = Method extends keyof paths[Path]
  ? ResponseFromRouteDefinition<paths[Path][Method]>
  : never;

export type APIRequestBody<
  Path extends keyof paths,
  Method extends keyof paths[Path],
> = Method extends keyof paths[Path] ? RequestFromRouteDefinition<paths[Path][Method]> : never;

export type OrType<BaseType, Or> = [BaseType] extends [never] ? Or : BaseType;

type RequestParametersFromRouteDefinition<RouteDefinition> = RouteDefinition extends RouteWithRequestParameters
  ? RouteDefinition["parameters"]
  : never;

type ResponseFromRouteDefinition<RouteDefinition> = RouteDefinition extends RouteWithJsonResponse
  ? RouteDefinition["responses"][200]["content"]["application/json"]
  : RouteDefinition extends RouteWithEmptyResponse
  ? void
  : never;

type RequestFromRouteDefinition<RouteDefinition> = RouteDefinition extends RouteWithJsonRequestBody
  ? RouteDefinition["requestBody"]["content"]["application/json"]
  : never;

export type MethodType = "get" | "put" | "post" | "patch" | "delete";

type BodyParam<
  Path extends keyof paths,
  Method extends MethodType & keyof paths[Path],
> = paths[Path][Method] extends RouteWithJsonRequestBody
  ? {
      body: paths[Path][Method]["requestBody"]["content"]["application/json"];
    }
  : {
      body?: never;
    };

type PathParamsParam<
  Path extends keyof paths,
  Method extends MethodType & keyof paths[Path],
> = paths[Path][Method] extends RouteWithRequestPathParameters
  ? {
      pathParams: paths[Path][Method]["parameters"]["path"];
    }
  : {
      pathParams?: never;
    };

type QueryParam<
  Path extends keyof paths,
  Method extends MethodType & keyof paths[Path],
> = paths[Path][Method] extends RouteWithRequestQueryParameters
  ? {
      query?: Partial<paths[Path][Method]["parameters"]["query"]>;
    }
  : {
      query?: never;
    };

/**
 * Copied and amended from `web-monorepo` `android-web/src/utils/callApi.ts`
 */
export type CallApiParams<Path extends keyof paths, Method extends MethodType & keyof paths[Path]> = {
  path: Path;
  method: Method;

  headers?: { [k: string]: string };
} & BodyParam<Path, Method> &
  PathParamsParam<Path, Method> &
  QueryParam<Path, Method>;

export function ensureSafeUrl<Path extends keyof paths, Method extends keyof paths[Path] & MethodType>({
  path,
  method,
  pathParams = {},
  query = {},
  body,
}: CallApiParams<Path, Method>): {
  method: Method;
  url: string;
  searchParams: URLSearchParams;
  urlWithQuery: string;
  body: string;
} {
  const builtPath = Object.entries(pathParams).reduce((acc, [key, value]) => {
    return acc.replace(`{${key}}`, value.toString());
  }, path as string);

  const searchParams = new URLSearchParams(Object.entries(query));
  const searchParamsStr = searchParams.toString();

  const urlWithQuery = searchParamsStr ? `${builtPath}?${searchParamsStr}` : builtPath;

  return {
    method,
    url: builtPath,
    searchParams,
    urlWithQuery,
    body: body ? JSON.stringify(body) : "",
  };
}

export type Prettify<T> = {
  [K in keyof T]: T[K];
  // eslint-disable-next-line @typescript-eslint/ban-types
} & {};
