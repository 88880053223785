import React, { forwardRef, useCallback } from "react";
import styled from "@emotion/styled";
import { theme } from "@src/components/nessie-web";
import { OTPInput, REGEXP_ONLY_DIGITS } from "input-otp";

function cn(...classes: (string | false | undefined | null)[]) {
  return classes.filter(Boolean).join(" ");
}

const VerificationInputContainer = styled.div`
  --cell-width: 48px;
  --cell-height: calc(var(--cell-width) * 1.5);
  --font-size: 35px;

  @media (max-width: 768px) {
    --cell-width: 38px;
    --cell-height: calc(var(--cell-width) * 1.5);
    --font-size: 24px;
  }

  margin-left: auto;
  margin-right: auto;

  & .container {
    display: flex;
    flex-direction: row;
    flex: 1;
    padding: 0;
    gap: 6px;
    justify-content: center;
  }

  .container > :nth-child(3 of .character) {
    margin-right: 6px;
  }

  .incorrect_code > .character {
    line-height: calc(var(--cell-height) - 2px); // 2px is the border width

    border-radius: 12px;
    border: 1px solid ${theme.colors.contentDanger};
    background: ${theme.colors.contentDanger5};

    color: ${theme.colors.contentDanger};
  }

  .incorrect_code > .character.selected {
    border: 3px solid ${theme.colors.contentDanger};

    line-height: calc(var(--cell-height) - 6px); // 6px is the border width
  }

  .character {
    text-transform: uppercase;

    font-size: var(--font-size);

    width: var(--cell-width);
    height: var(--cell-height);
    line-height: var(--cell-height);

    // Center text
    text-align: center;
    vertical-align: middle;

    border-radius: 12px;
    border: none;

    background: ${theme.colors.midnight5};

    outline: none;
  }

  .character.selected {
    border: 3px solid ${theme.colors.primaryBorderColor};

    line-height: calc(var(--cell-height) - 6px); // 6px is the border width
  }
`;

type Props = {
  onChange(code: string): void;
  onComplete(code: string): void;
  length: number;
  value: string;
  autoFocus?: boolean;
  showError?: boolean;
  inputProps?: React.InputHTMLAttributes<HTMLInputElement>;
};

export const sanitizeCodeInput = (value: string, length = 6) => {
  if (!value) return "";
  return value
    .split("")
    .map((char) => mapFilterChar(char))
    .filter((char) => typeof char === "string")
    .join("")
    .slice(0, length);
};

const mapFilterChar = (char: string) => (/[0-9]/.test(char) ? char : null);

export const OTCInput = forwardRef<HTMLInputElement, Props>(function OTCInput(
  { value, onChange: onParentChange, onComplete, length, showError = false, inputProps },
  ref,
) {
  const onChange = useCallback(
    (eventValue: string) => {
      // Map and filter characters
      const newValue = sanitizeCodeInput(eventValue, length);

      // Don't edit if at max length
      if (value.length === length && newValue.length === length) return;

      // Ignore if the value is the same
      if (newValue === value) return;

      onParentChange(newValue);

      if (newValue.length === length) {
        onComplete(newValue);
      }
    },
    [length, value, onParentChange, onComplete],
  );

  return (
    <VerificationInputContainer>
      <OTPInput
        ref={ref}
        maxLength={length}
        value={value}
        autoFocus
        onChange={onChange}
        onComplete={onComplete}
        pattern={REGEXP_ONLY_DIGITS}
        pasteTransformer={sanitizeCodeInput}
        containerClassName={cn("container", showError && "incorrect_code")}
        render={({ slots }) =>
          slots.map((slot, index) => (
            <span key={index} aria-hidden className={cn("character", slot.isActive && "selected")}>
              {value[index] ?? ""}
            </span>
          ))
        }
      />
    </VerificationInputContainer>
  );
});
