import React, { SyntheticEvent, useCallback, useContext, useEffect, useState } from "react";
import styled from "@emotion/styled";
import { DojoIslandsModal } from "./DojoIslandsModal";
import dojoislandslogo from "@src/assets/images/dojo-islands/play/dojoislandslogo-90.webp";
import { Title } from "@src/components/nessie-web";
import { mediaQueries } from "@src/styles/theme";
import Translate from "@src/components/translation/Translate";
import DojoIslandsButton from "@src/components/DojoIslandsButton";
import { Column, Row } from "@src/components/extended";
import { TranslationContext } from "@src/components/translation/TranslationContext";
import InputLabel from "@src/components/forms/InputLabel";
import { incrementMetric, logEvent } from "@src/utils/logClient";
import { AppDataContext } from "@src/components/AppDataContext";
import { useDojoIslandsLoginWithEmail } from "./data";

const bigDeviceVersion = mediaQueries[0];
const DojoIslandsModalHeader = styled(Title)`
  text-transform: uppercase;
  font-family: "Grandstander";
  font-weight: 800;
  font-size: 28px;
  line-height: 34px;
  letter-spacing: normal;
  font-feature-settings: "liga" off;
  text-align: center;
  color: #1a192d;
  ${bigDeviceVersion} {
    font-size: 35px;
    line-height: 42px;
    letter-spacing: -0.005em;
  }
`;

const DojoIslandsModalSubHeader = styled(Title)`
  font-family: "OmoTypeCBlack";
  font-weight: 400;
  font-size: 15px;
  line-height: 130%;
  letter-spacing: 0.02em;
  text-align: center;
  color: #5d5d8f;
  ${bigDeviceVersion} {
    font-size: 18px;
  }
`;

const ResponsiveDojoIslandsButton = styled(DojoIslandsButton)`
  width: 100%;
`;

const ResponsiveDojoIslandsLogo = styled("img")`
  width: 144px;

  ${bigDeviceVersion} {
    width: 180px;
  }
`;

const emailRegex = new RegExp(
  /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
);

type LoginStatus = "input" | "submitting" | "waitlist" | "error";

export const DojoIslandsLoginModal = ({
  onClose,
  onSent,
}: {
  onClose: () => void;
  onSent: (parentEmail: string) => void;
}) => {
  const t = useContext(TranslationContext);
  const {
    data: { loadingSession, userData, geolocation },
  } = useContext(AppDataContext);
  const hasSession = !!userData;
  const entityType = userData?.type;
  const country = geolocation?.countryCode;

  const [loginStatus, setLoginStatus] = useState<LoginStatus>("input");
  const { loginWithEmail } = useDojoIslandsLoginWithEmail();

  useEffect(() => {
    if (!loadingSession) {
      logEvent({
        eventName: "web.external_page.dojo_islands_play.login_modal.page_view",
        metadata: { hasSession, entityType, country },
      });
    }
  }, [loadingSession, country, entityType, hasSession]);

  const submitParentEmail = useCallback(
    async (parentEmail: string) => {
      setLoginStatus("submitting");

      try {
        const result = await loginWithEmail(parentEmail);
        if (result === "waitlist") {
          // send product event for waitlist
          logEvent({
            eventName: "web.external_page.dojo_islands_play.login_modal.submit.tap",
            eventValue: parentEmail,
            metadata: { hasSession, entityType, country },
          });
          incrementMetric("external_site.dojo_islands_play.login_modal_submit");
          setLoginStatus("waitlist");
        } else if (result === "sent") {
          // login one time code has been sent to email
          logEvent({
            eventName: "web.external_page.dojo_islands_play.otc.email.sent",
            eventValue: parentEmail,
            metadata: { hasSession, entityType, country },
          });
          incrementMetric("external_site.dojo_islands_play.otc_email_sent");
          onSent(parentEmail);
        } else if (result === "disabled") {
          // login one time codes are temporarily disabled
          logEvent({
            eventName: "web.external_page.dojo_islands_play.login_disabled",
            eventValue: parentEmail,
            metadata: { hasSession, entityType, country },
          });
          setLoginStatus("error");
        }
      } catch (err) {
        setLoginStatus("error");
      }
    },
    [country, entityType, hasSession, loginWithEmail, onSent],
  );

  if (loadingSession) {
    return null;
  }

  return (
    <DojoIslandsModal labelledby="modal-heading" closeModal={onClose}>
      <Column alignItems="stretch" gap={[24, 30]}>
        <Row justifyContent="center">
          <ResponsiveDojoIslandsLogo src={dojoislandslogo} alt="dojo islands logo" />
        </Row>

        {loginStatus === "waitlist" ? (
          <ParentEmailSubmitted />
        ) : (
          <ParentEmailForm submitParentEmail={submitParentEmail} submitting={loginStatus === "submitting"} />
        )}
      </Column>
    </DojoIslandsModal>
  );
};

type ParentEmailFormProps = {
  submitParentEmail: (parentEmail: string) => void;
  submitting: boolean;
};
const ParentEmailForm = ({ submitParentEmail, submitting }: ParentEmailFormProps) => {
  const t = useContext(TranslationContext);
  const [parentEmail, setParentEmail] = useState("");
  const [parentEmailValid, setParentEmailValid] = useState(false);

  const validateParentEmail = useCallback((value: string) => {
    setParentEmail(value);
    setParentEmailValid(emailRegex.test(value));
  }, []);

  const onSubmit = useCallback(
    (event: SyntheticEvent) => {
      event.preventDefault();

      if (parentEmailValid) {
        submitParentEmail(parentEmail);
      }
    },
    [parentEmail, parentEmailValid, submitParentEmail],
  );

  return (
    <Column gap={[24, 30]}>
      <Column alignItems="center" gap={12}>
        <DojoIslandsModalHeader id="modal-heading">
          <Translate path="pages.dojo_islands_play.login_modal.title" />
        </DojoIslandsModalHeader>

        <DojoIslandsModalSubHeader>
          <Translate path="pages.dojo_islands_play.login_modal.subtitle" />
        </DojoIslandsModalSubHeader>
      </Column>

      <form onSubmit={onSubmit}>
        <InputLabel
          rounded
          name="parentEmail"
          type="email"
          label={t.translate("pages.dojo_islands_play.login_modal.parent_email_label").toString()}
          value={parentEmail}
          onChange={(event) => validateParentEmail(event.target.value)}
          autoComplete="off"
          autoFocus
          required
          disabled={submitting}
        />

        <ResponsiveDojoIslandsButton type="submit" disabled={!parentEmailValid || submitting} onClick={onSubmit}>
          <Translate path="pages.dojo_islands_play.login_modal.continue_button" />
        </ResponsiveDojoIslandsButton>
      </form>
    </Column>
  );
};

const ParentEmailSubmitted = () => {
  return (
    <Column alignItems="center" gap={12}>
      <DojoIslandsModalHeader id="modal-heading">
        <Translate path="pages.dojo_islands_play.login_modal.waitlist_confirmation" />
      </DojoIslandsModalHeader>

      <DojoIslandsModalSubHeader>
        <Translate path="pages.dojo_islands_play.login_modal.waitlist_confirmation_subtitle" />
      </DojoIslandsModalSubHeader>
    </Column>
  );
};
