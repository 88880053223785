import { useCallback, useEffect, useState } from "react";
import { isDev } from "@src/utils/env";
import { callApi } from "@src/utils/api-types/callApi";

export const LANDING_PAGE_ACTIONS = ["no_action", "island_selection", "player_selection"] as const;
export type LandingPageAction = (typeof LANDING_PAGE_ACTIONS)[number];

export function useDojoIslandsLandingPageAction(active: boolean) {
  const [state, setState] = useState<{ loading: boolean; action: LandingPageAction }>({
    loading: active,
    action: "no_action",
  });

  useEffect(() => {
    if (active) {
      setState({ loading: true, action: "no_action" });

      callApi({
        path: "/api/monsterverse/landing-action",
        method: "get",
      })
        .then((response) => {
          setState({ loading: false, action: response.action });
        })
        .catch((err) => {
          setState({ loading: false, action: "no_action" });
        });
    }
  }, [active]);

  return state;
}

export type DojoLoginWithEmailResult = "waitlist" | "sent" | "disabled";

export function useDojoIslandsLoginWithEmail() {
  const loginWithEmail = useCallback(async (parentEmail: string): Promise<DojoLoginWithEmailResult> => {
    return callApi({
      path: "/api/monsterverse/one-time-code",
      method: "post",
      body: { email: parentEmail },
    }).then((response) => {
      return response.result;
    });
  }, []);

  return {
    loginWithEmail,
  };
}

export function navigateToAction(action: LandingPageAction) {
  if (action === "island_selection") {
    if (window) {
      const redirectUrl = isDev()
        ? "https://student.classdojo.test:8084/#/home/islands"
        : "https://student.classdojo.com/#/home/islands";
      window.location.replace(redirectUrl);
    }
  } else if (action === "player_selection") {
    if (window) {
      const redirectUrl = isDev()
        ? "https://home.classdojo.test:8085/#/dojoislands/kids"
        : "https://home.classdojo.com/#/dojoislands/kids";
      window.location.replace(redirectUrl);
    }
  }
}

/**
 * Wrapper around `Array.includes()` that is also a type guard and doesn't err if the value
 * is not guaranteed to be in the given array
 *
 * @param arr
 * @param value
 */
export function includes<TArr extends readonly unknown[]>(
  //
  arr: TArr,
  value: unknown,
): value is TArr[number] {
  // @ts-expect error
  return arr.includes(value);
}
