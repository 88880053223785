import React, { useCallback, useContext, useEffect } from "react";
import { AppDataContext } from "@src/components/AppDataContext";
import { PlayDojoIslandsPage } from "./PlayDojoIslandsPage";
import { includes, LANDING_PAGE_ACTIONS, navigateToAction, useDojoIslandsLandingPageAction } from "./data";
import { incrementMetric, logEvent } from "@src/utils/logClient";
import useSearchParams from "@src/utils/useSearchParams";

export const DojoIslandsLandingPageActions = () => {
  const {
    data: { loadingSession, userData, geolocation },
  } = useContext(AppDataContext);
  const [forcedAction] = useSearchParams("action");
  const [email] = useSearchParams("email");

  const hasSession = !!userData;
  const entityType = userData?.type;
  const country = geolocation?.countryCode;

  // only fetch landing page action if there is a user session
  // and it's not the magic-login link case
  const checkLandingAction = hasSession && !email && !forcedAction;

  const { loading: loadingAction, action: apiAction } = useDojoIslandsLandingPageAction(checkLandingAction);

  let action = apiAction;
  if (includes(LANDING_PAGE_ACTIONS, forcedAction)) {
    action = forcedAction;
  }

  const actOnAction = useCallback(() => {
    if (loadingSession || loadingAction) {
      return;
    }

    logEvent({
      eventName: "web.external_page.dojo_islands_play.page_view",
      metadata: { hasSession, entityType, country, action },
    });
    incrementMetric("external_site.dojo_islands_play.page_view", { action, entityType: entityType || "-" });

    navigateToAction(action);
  }, [action, country, entityType, hasSession, loadingAction, loadingSession]);

  useEffect(() => {
    actOnAction();
  }, [actOnAction]);

  return <PlayDojoIslandsPage />;
};
