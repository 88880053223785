import { paths } from "@classdojo/ts-api-types";
import { APIResponse, CallApiParams, ensureSafeUrl, MethodType } from "./ensureSafeUrl";
import { getDefaultApiEndpoint } from "@src/components/mobile/views/code/utils";
import axios from "axios";

export async function callApi<Path extends keyof paths, Method extends keyof paths[Path] & MethodType>(
  opts: CallApiParams<Path, Method>,
): Promise<APIResponse<Path, Method>> {
  const { url, method, searchParams } = ensureSafeUrl(opts);

  const result = await axios.request({
    baseURL: getDefaultApiEndpoint(),
    url,
    method,
    params: searchParams,
    data: opts.body,
    responseType: "json",
  });

  return result.data;
}
